/**
 * SEO component that queries for data with
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import Helmet from "react-helmet";
import { StaticQuery, graphql } from "gatsby";
import referralImage from '../images/antareep-referral-fb-post.png';

const seoStaticQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
        author
      }
    }
  }
`;

const SEO = ({ description, lang, meta, title, product, productDetails }) => (
  <StaticQuery<GatsbyTypes.Query>
    query={`${seoStaticQuery}`}
    render={({ site }) => {
	  let schemaProduct = null

	  if (product) {
			schemaProduct = {
		  "@context":"https://schema.org",
		  "@type":"Product",
		  "productID": `${productDetails.productId}`,
		  "name": productDetails.name,
		  "description": productDetails.description,
		  "url": `https://antareep.in/product/${productDetails.handle}`,
		  "image": productDetails.image,
		  "brand":"antareep",
		  "offers": [
			{
			  "@type": "Offer",
			  "price": productDetails.price,
			  "priceCurrency": "INR",
			  "itemCondition": "https://schema.org/NewCondition",
			  "availability": productDetails.availableForSale ? "https://schema.org/InStock" : "https://schema.org/OutOfStock"
			}
		  ]
		}
	  }      
      const metaDescription = description || site.siteMetadata.description;
      return (
        <Helmet
          htmlAttributes={{
            lang,
          }}
          title={title}
          titleTemplate={`%s | ${site.siteMetadata.title}`}
          meta={[
            {
              name: `description`,
              content: metaDescription,
            },
            {
              property: `og:title`,
              content: title,
            },
            {
              property: `og:description`,
              content: metaDescription,
            },
            {
              property: `og:type`,
              content: `website`,
            },
            {
              name: `twitter:card`,
              content: `summary`,
            },
            {
              name: `twitter:creator`,
              content: site.siteMetadata.author,
            },
            {
              name: `twitter:title`,
              content: title,
            },
            {
              name: `twitter:description`,
              content: metaDescription,
            },
          ].concat(meta)}
        >
        {product && <script type="application/ld+json">{JSON.stringify(schemaProduct)}</script>}
        </Helmet>
      );
    }}
  />
);

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

// SEO.propTypes = {
//   description: PropTypes.string,
//   lang: PropTypes.string,
//   meta: PropTypes.arrayOf(PropTypes.object),
//   title: PropTypes.string.isRequired,
// }

export default SEO;
