/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-south-1",
	"aws_cognito_region": "ap-south-1",
	"aws_user_pools_id": "ap-south-1_sqESkxDgv",
	"aws_user_pools_web_client_id": "7g0n8d0f24kaiqmharucoivuv0",
    "aws_appsync_graphqlEndpoint": "https://roo6sut7erba7dnk5fvyu4cmvq.appsync-api.ap-south-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-south-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "null",
};

export default awsmobile;
