const styles = {
	wrapper: {
		display: 'flex',
		flexDirection: 'column',
		position: 'relative',
		'@media only screen and (max-width: 480px)': {
			padding: '0px 30px 30px',
		},
	},
	title: {
		color: 'primary',
		fontWeight: '600',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-start',
		width: '100%',
		marginY: '10px',
		marginLeft: '10px',
	},	
	buttonStyle: {
		width: '100%',
		maxWidth: 750,
		marginX: 'auto',
		backgroundColor: 'white',
		position: 'relative',
		marginTop: '-5vh',
		'&::before': {
			content: '""',
			width: '100%',
			height: '30px',
			background:
				'linear-gradient(to top, rgba(255, 255, 255, 0.7) , rgba(255, 255, 255, 0))',
			zIndex: 1,
			pointerEvents: 'none',
			'@media only screen and (max-width: 480px)': {
				display: 'none',
			},
		},
		button: {
			width: '100%',
			svg: {
				position: 'relative',
				top: '1px',
				marginRight: '10px',
			},
		},
	},
};

export default styles;
