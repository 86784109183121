/** @jsx jsx */
import React, { useState } from 'react';
import { Box, jsx } from 'theme-ui';
import { Waypoint } from 'react-waypoint';
import { animated, useSpring } from 'react-spring';
import CartStatus from '../../cart/index';
import Container from '../../container/container';
import Header from './header/header';
import Footer from './footer/footer';
import styles from './primary.style';
//import Wapp from '../../popup/wapp';
//import { ReactComponent as CompanyIcon } from '../../../images/chat2order_rsz2.svg';
import ChatIcon from '../../chatIcon';
import { WhatsAppWidget } from 'react-whatsapp-widget';
import '../../../react-whatsapp-widget/index.css';

const PrimaryLayout: React.FunctionComponent<{
	fluid?: boolean;
	showNoticeBar?: boolean;
	bgColor?: string;
	homeLink?: string;
	pathPrefix?: string;
}> = ({ fluid, showNoticeBar, bgColor, homeLink, pathPrefix, children }) => {
	const [cartBar, setCartBar] = useState(false);
	const cartBtnProps = useSpring({
		config: { mass: 1, tension: 500, friction: 48 },
		bottom: cartBar ? 87 : 30,
	});
	const welcometext1 = `Hello! 👋🏼 What can we do for you? Type below or visit`
	const welcometext2 = `for more sellers and products!`
	const replyTimeText = 'Typically replies in few mins'
	return (
		<Box
			as="main"
			sx={{
				backgroundColor: bgColor ? bgColor : 'transparent',
				...styles.main,
			}}
		>
			<Header
				fluid={fluid}
				homeLink={homeLink}
				pathPrefix={pathPrefix}
				showNoticeBar={showNoticeBar}
			/>
			<Container fluid={fluid}>{children}</Container>
			<Waypoint
				onEnter={() => setCartBar(true)}
				onLeave={() => setCartBar(false)}
			/>
			<animated.div
				style={{ ...cartBtnProps, position: 'relative', zIndex: 9999 }}
			>
				<CartStatus btnProps={cartBtnProps} />
			</animated.div>
			<Footer fluid={fluid} />
			<WhatsAppWidget replyTimeText={replyTimeText} sendButtonText="Whatsapp" message1={welcometext1} url="https://shops.chat2order.in" message2={welcometext2} companyName="Chat2Order" CompanyIcon={ChatIcon} phoneNumber="+918777675366" />					
		</Box>
	);
};

export default PrimaryLayout;
