/** @jsx jsx */
import { useState, useEffect } from 'react';
import { Box, Text, Button, jsx } from 'theme-ui';
import { RichText } from 'prismic-reactjs';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import useWindowSize from '../../hooks/useWindowSize';
import styles from './call-us-banner.style';
import { Whatsapp } from '../icons';

const CallusBanner = (props: any) => {
	const windowSize = useWindowSize();
	const [offset, setOffset] = useState(60);
	const {
		callUsBanner,
		callUsTitle,
		callUsButtonText,
		scrollTo = '#feature',
	} = props;
	useEffect(() => {
		if (windowSize < 1081) {
			setOffset(80);
		} else {
			setOffset(60);
		}
	}, [windowSize]);

	return (
		<Box
			as="section"
			className="call-us-banner"
			sx={{
				backgroundImage: `url(${
					callUsBanner && callUsBanner.url ? callUsBanner.url : ''
				})`,
				...styles.banner,
			}}
		>
			<Box as="header" sx={styles.bannerContent}>
				{RichText.render(callUsTitle)}
				<AnchorLink
					href="https://wa.me/918777675366?text=Hi"
					style={{ textDecoration: 'none' }}
					offset={() => offset}
				>
					<Button variant="green" onClick={() => { if (typeof window !== "undefined") {window.open("https://wa.me/918777675366?text=Hi")}}}>
						<Whatsapp />
					</Button>
				</AnchorLink>
			</Box>
		</Box>
	);
};

export default CallusBanner;
