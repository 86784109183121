import React from 'react';
import { Flex, Box, Grid, Heading, Text, jsx, Button } from "theme-ui";
import ProductCard from "../product-card-dynamic/product-card-dynamic";
import styles from "./product-grid-dynamic.style";

type PropsType = {
  id?: string;
  close?: Function;
  isCategoryProduct?: boolean;
  gridTitle?: string;
  products: any;
  noProduct: string;
};

const ProductGridDynamic: React.FC<PropsType> = ({
  id,
  close,
  gridTitle,
  products,
  isCategoryProduct = false,
  noProduct = ""
}) => {
  const getPrice = (price: any) =>
    Intl.NumberFormat(undefined, {
      currency: 'INR',
      minimumFractionDigits: 2,
      style: "currency",
    }).format(parseFloat(price && price.amount ? price.amount : price));
	var count = 0;
  return (
  <>
    {products?.length > 0 &&
    (<Box id={id} sx={styles.wrapper}>
      <Flex sx={styles.header}>
        {gridTitle && <Heading as="h3">{gridTitle}</Heading>}
        {close && (
          <Button variant="text" onClick={close}>
            Clear
          </Button>
        )}
      </Flex>
      {products?.length > 0 ? (
        <Grid sx={styles.productGrid}>
          {products.map((product: any) => {
            /*const {
              id,
              title,
              shopifyId,
              variants,
              availableForSale,
              images: [firstImage],
              variants: [firstVariant],
            } = isCategoryProduct ? product : product.node;*/
			const id = count ++;
			const title = product.node.title;
			const shopifyId = product.node.id;
			const variants = product.node.variants.edges;
			const availableForSale = true;
			const firstImage = product.node.images.edges[0].node;
			const firstVariant = product.node.variants.edges[0];
			const handle = product.node.handle;
            return (
              <ProductCard
                path={`/newproduct/${handle}`}
                key={id}
                title={title}
                shopifyId={shopifyId}
                availableForSale={availableForSale}
                price={getPrice(firstVariant?.node.price.amount)}
                thumbnail={firstImage?.originalSrc}
                variants={variants}
              />
            );
          })}
        </Grid>
      ) : (
        <Text>{"No Products found!" + noProduct}</Text>
      )}
    </Box>)}
    </>
  );
};

export default ProductGridDynamic;
