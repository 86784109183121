const styles = {
	wrapper: {
		py: [20, 30],
	},
	grid: {
		gridGap: [20, 30],
		gridTemplateColumns: '1fr',
		'@media only screen and (min-width: 568px)': {
			gridTemplateColumns: '1fr 1fr',
		},
		'@media only screen and (min-width: 992px)': {
			gridTemplateColumns: '1fr 1fr 1fr',
		},
	},
	header: {
		width: '100%',
		alignItems: 'center',
		justifyContent: ['space-between', 'flex-start'],
		marginBottom: [20, 30],
		button: {
			textDecoration: 'underline',
			marginLeft: [0, '5px'],
		},
	},	
};

export default styles;
