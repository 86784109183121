import React, { useContext, useState } from 'react';
import { CartContext } from '../../provider/cart-provider';
import { LocalCartContext } from '../../provider/local-cart-provider';
import { Spinner, Container } from 'theme-ui'
import awsconfig from '../../aws-exports';
//import Amplify from 'aws-amplify';
import { Amplify, Auth } from 'aws-amplify';

Amplify.configure(awsconfig);

const WebCheckout = ({onClick, setLoggedIn}) => {
	const { products, totalPrice, currency, clearCart, setLoggedInUser } = useContext(
		LocalCartContext
	);
	const { store, addToCartAndCheckout } = useContext(CartContext);
	const { checkout, updating } = store;
	const handleCheckout = () => {
		addToCartAndCheckout();
		clearCart();
		if(window.self !== window.top) {
		  onClick();
		}
	};	
    React.useEffect(() => {
		const checkAuth = async () => {
			const user = await Amplify.Auth.currentUserInfo();
			console.log("user:",JSON.stringify(user))
			//setIsOpen(true);
			if (user) {
				setLoggedInUser(user.username.substring(1))
			}	 
		};
		checkAuth()
		.then( () => {
    	setLoggedIn(true)
    	handleCheckout();		
		});    	

    }, []);
	//console.log("user: ", user)
	return (
	<Container sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}} >
		<Spinner />
	</Container>
	)
}
export default WebCheckout;