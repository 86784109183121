import React from "react";
import { StaticQuery, graphql } from "gatsby";
import ProductGridDynamic from "../product-grid-dynamic/product-grid-dynamic";

const LatestProductsHome = () => {
	const [newProducts, setNewProducts] = React.useState([]);
	
   React.useEffect(() => {
	  (async () => {
		const query = `
{
  products(first:10, query:"created_at:>${process.env.GATSBY_BUILD_TIME}", sortKey: CREATED_AT, reverse: true) {
    edges {
      node {
        id
        title
        createdAt
        handle
        variants (first: 1){
          edges {
            node {
			  id
			  availableForSale
              price	{
              	amount
              }  	  
            }
          }
        }
        tags
        images  (first: 2){
          edges {
            node {
              originalSrc
            }
          }
        }
      }
    }
  }
}`;

		var requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/graphql',
						'Accept': 'application/json',
						'X-Shopify-Storefront-Access-Token': process.env.GATSBY_SHOPIFY_ACCESS_TOKEN},
			body: query
		};

		const response = await fetch(`https://${process.env.GATSBY_SHOP_NAME}.myshopify.com/api/2020-07/graphql`, requestOptions );
		let productJson = await response.json();
	  	setNewProducts(productJson.data?.products?.edges);
	  })();
	     	
  }, [])

	return (
		<ProductGridDynamic
		   id="recipe"
		   gridTitle="New releases"
		   products={newProducts}
		   noProduct={``}		  
		/>
	);

};

export default LatestProductsHome;
